import BadgeLink from 'components/BadgeLink'
import CategoryHero from 'components/CategoryHero'
import CategoryList from 'components/CategoryList'
import Image from 'components/Image'
import Pagination from 'components/Pagination'
import PostsList from 'components/PostsList'
import SEO from 'components/SEO'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import theme from 'styles/theme'

const PostListTemplate = ({
  data: {
    featuredPostData: {
      post: [featuredPost],
    },
    postData: { posts: normalPosts },
    categoryData: { categories },
  },
  pageContext: { basePath, pageCount, currentPageNumber, pageUrl },
}) => (
  <>
    <SEO pageMeta={{ metaTitle: 'Learn', noIndex: currentPageNumber !== 1 }} pagePath={pageUrl} />
    <CategoryList categories={categories} activeCategoryName='All' />
    <CategoryHero
      category={featuredPost.category}
      title={featuredPost.title}
      subtext={featuredPost.excerpt}
      image={featuredPost.image}
      link={featuredPost.slug.current}
    />

    <PostsList posts={normalPosts} />

    <Pagination basePath={basePath} currentPageNumber={currentPageNumber} pageCount={pageCount} />
  </>
)

const postPropData = PropTypes.shape({
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  slug: PropTypes.shape({ current: PropTypes.string.isRequired }).isRequired,
  category: PropTypes.shape({
    title: PropTypes.string.isRequired,
    slug: PropTypes.shape({
      current: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  image: PropTypes.object.isRequired,
}).isRequired

PostListTemplate.propTypes = {
  pageContext: PropTypes.shape({
    basePath: PropTypes.string.isRequired,
    pageCount: PropTypes.number.isRequired,
    currentPageNumber: PropTypes.number.isRequired,
    pageUrl: PropTypes.string.isRequired,
  }).isRequired,
  data: PropTypes.shape({
    featuredPostData: PropTypes.shape({
      post: PropTypes.arrayOf(postPropData).isRequired,
    }),
    postData: PropTypes.shape({
      posts: PropTypes.arrayOf(postPropData).isRequired,
    }),
    categoryData: PropTypes.shape({
      categories: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          title: PropTypes.string.isRequired,
          slug: PropTypes.shape({ current: PropTypes.string.isRequired }).isRequired,
        }).isRequired
      ).isRequired,
    }).isRequired,
  }).isRequired,
  path: PropTypes.string.isRequired,
}

const Hero = ({ post: { title, slug, category, excerpt, image } }) => (
  <div className='Hero'>
    <a
      href={slug.current}
      css={{
        display: 'flex',
        textDecoration: 'inherit',
        transition: theme.fastTransition,
        ':hover': {
          boxShadow: '12px 12px 0 0' + theme.colors.spinachLight,
        },
      }}
    >
      <Image {...image} width={1280} height={600} alt={title} />
    </a>

    <div>
      {category &&
        category.map(cat => (
          <BadgeLink
            to={cat.slug.current}
            css={{
              margin: '2rem 0 0',
            }}
          >
            {cat.title}
          </BadgeLink>
        ))}
      <h1
        css={{
          ...theme.h1,
          color: theme.colors.spinachDark,
          textDecoration: 'none',
          maxWidth: '9em',
          margin: '2rem 0',
        }}
      >
        <a
          href={slug.current}
          css={{
            textDecoration: 'inherit',
            transition: theme.globalTransition,
            ':hover': {
              color: theme.colors.spinach,
            },
          }}
        >
          {title}
        </a>
      </h1>
      <p
        css={{
          ...theme.lead,
          maxWidth: '36em',
        }}
      >
        {excerpt}
      </p>
    </div>
  </div>
)

Hero.propTypes = {
  post: PropTypes.shape({
    title: PropTypes.string.isRequired,
    slug: PropTypes.object.isRequired,
    category: PropTypes.array.isRequired,
    excerpt: PropTypes.string.isRequired,
    image: PropTypes.object.isRequired,
  }),
}

export default PostListTemplate

export const query = graphql`
  fragment PostListPostFragment on SanityPost {
    id
    title
    slug {
      current
    }
    category {
      title
      slug {
        current
      }
    }
    image {
      ...ImageWithPreview
    }
    excerpt
  }

  query PostList($limit: Int!, $skip: Int!) {
    featuredPostData: allSanityPost(limit: 1, sort: { fields: [publishedAt], order: DESC }) {
      post: nodes {
        ...PostListPostFragment
      }
    }
    postData: allSanityPost(
      limit: $limit
      skip: $skip
      sort: { fields: [publishedAt], order: DESC }
    ) {
      posts: nodes {
        ...PostListPostFragment
      }
    }
    categoryData: allSanityCategory(sort: { fields: [title], order: ASC }) {
      categories: nodes {
        id
        title
        slug {
          current
        }
        image {
          ...ImageWithPreview
          asset {
            _id
            url
          }
        }
      }
    }
  }
`

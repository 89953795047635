import { builder as imageBuilder } from 'components/Image'
import { graphql, useStaticQuery } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import Helmet from 'react-helmet'

const SEO = ({ defaultMeta, pageMeta, pagePath }) => {
  defaultMeta = defaultMeta || {}
  pageMeta = pageMeta || {}

  const {
    siteSettings: { url, titleTemplate, defaultMeta: siteMeta },
  } = useStaticQuery(graphql`
    {
      siteSettings: sanitySiteSettings {
        url
        titleTemplate
        defaultMeta {
          ...MetaCard
        }
      }
    }
  `)

  const title = pageMeta.metaTitle || defaultMeta.metaTitle || siteMeta.metaTitle
  const description =
    pageMeta.metaDescription || defaultMeta.metaDescription || siteMeta.metaDescription
  const ogTitle = pageMeta.ogTitle || siteMeta.ogTitle || title
  const ogDescription = pageMeta.ogDescription || siteMeta.ogDescription || description
  const ogImage = imageBuilder
    .image(pageMeta.ogImage || siteMeta.ogImage)
    .width(1080)
    .url()
  const twitterTitle = pageMeta.twitterTitle || siteMeta.twitterTitle || title
  const twitterDescription =
    pageMeta.twitterDescription || siteMeta.twitterDescription || description
  const twitterImage = imageBuilder
    .image(pageMeta.twitterImage || siteMeta.twitterImage)
    .width(1080)
    .url()
  const noIndex = pageMeta.noIndex
  const canonicalPath = pageMeta.canonicalPath || pagePath

  return (
    <Helmet title={title} titleTemplate={titleTemplate}>
      <html lang='en' />
      {noIndex && <meta name='robots' content='noindex' />}
      <meta httpEquiv='Accept-CH' content='DPR, Width, Viewport-Width' />
      <meta property='og:locale' content='en_US' />
      <meta name='description' content={description} />
      <meta property='og:url' content={`${url}${pagePath}`} />
      <meta property='og:image' content={ogImage} />
      <meta property='og:title' content={`${ogTitle}`} />
      <meta property='og:site_name' content='Owyn' />
      <meta property='og:description' content={ogDescription} />
      <meta name='twitter:site' content='@liveowyn' />
      <meta name='twitter:card' content='summary_large_image' />
      <meta name='twitter:title' content={`${twitterTitle}`} />
      <meta name='twitter:image:src' content={twitterImage} />
      <meta name='twitter:description' content={twitterDescription} />
      <meta name='twitter:url' content={`${url}${pagePath}`} />

      <link rel='canonical' href={`${url}${canonicalPath}`} />
    </Helmet>
  )
}

SEO.propTypes = {
  pagePath: PropTypes.string.isRequired,
  defaultMeta: PropTypes.object,
  pageMeta: PropTypes.object,
}

export default SEO
